export function useHubspot(hubspotId) {
  if (!hubspotId) return

  const script = document.createElement('script');
  script.src = `//js-eu1.hs-scripts.com/${hubspotId}.js`;
  script.async = true;
  script.defer = true;

  document.body.appendChild(script)

  return () => {
    document.body.removeChild(script);
  }
}
