export const DEFAULT_STATE = {
  email: '',
  region: '',
  commune: '',
  county: '',
  name: '',
  phone: '',
  consentBasic: undefined,
  consentEmail: undefined,
  accept: undefined,
  assistanceHours: '',
  formError: false,
}

export function handleInputChange(e, setState) {
  const { name, value } = e.target;

  setState((prevState) => ({
    ...prevState,
    [name]: value,
  }));
}

export function handleSelect(region, commune, county, setState) {
  setState((prevState) => ({
    ...prevState,
    region,
    commune,
    county,
  }));
}
