import React, { useContext, useEffect } from 'react';
import config from '../utils/config';
import { QuizContext } from '../context/QuizContext';
import Home from './Home';
import Quiz from './Quiz';
import Result from './Result';
import GoogleTagManager from '../utils/GoogleTagManager';
import Header from './Header';
import { useHubspot } from '../hooks';

export default function App() {
  useEffect(() => useHubspot(process.env.REACT_APP_HUBSPOT_ID), [])

  const { data } = useContext(QuizContext);

  return (
    <>
      <GoogleTagManager gtmId={config.gtmId} />
      <Header />
      {!data.started && !data.completed && <Home />}
      {data.started && !data.completed && <Quiz />}
      {data.started && data.completed && <Result />}
    </>
  );
}
