export default {
  gtmId: 'GTM-KJV99S8',
  profilerURLs: {
    application: `${process.env.REACT_APP_HUBSPOT_API_URL}/${process.env.REACT_APP_HUBSPOT_FORM_ID_APPLICATION}`,
    inquiry: `${process.env.REACT_APP_HUBSPOT_API_URL}/${process.env.REACT_APP_HUBSPOT_FORM_ID_INQUIRY}`,
  },
  characteristics: {
    childOrAdult: 'child_or_adult',
    commune: 'city',
    region: 'country',
    county: 'state',
    message: 'message',
    bpaRights: 'right_to_bpa',
    accept: 'call',
    assistanceHours: 'assistance_per_week',
    consentBasic: 'concent_basic',
    consentEmail: 'concent_email',
  },
  resultOptions: {
    noRights: 'no_rights',
    notLawfully: 'not_lawfully_right',
    lawfully: 'lawfully_right',
  },
  resultPaths: {
    noRightsForBPA: {
      // if includes....
      path: ['q_1_adult:no', 'q_2_children:no'],
    },
    notLawfullyRightsForBPA: {
      // if includes....
      path: [
        'q_3_children:25-31',
        'q_3_adult:25-31',
        'q_2_adult:no',
        'q_3_children:0-24',
        'q_3_adult:0-24',
      ],
    },
  },
};
